const AwsConfig = {
  Auth: {
    // identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEBCLIENT_ID,
    // mandatorySignIn: true,
    // cookieStorage: {
    //   domain: process.env.REACT_APP_DOMAIN,
    //   path: '/',
    //   expires: 365,
    //   secure: true,
    // },
  },
  // aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_URL,
  // aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  // aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // graphql_headers: async () => {
  //   return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
  // },
}

export default AwsConfig

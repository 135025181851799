import { withAuthenticator } from '@aws-amplify/ui-react'
import { CssBaseline, makeStyles, Theme } from '@material-ui/core'
// import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignOut, VerifyContact, withAuthenticator } from 'aws-amplify-react'
import { observer } from 'mobx-react-lite'
import React, { lazy, Suspense } from 'react'
// import { useTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router'
import './App.css'

const HomePage = lazy(() => import('./pages/home'))

const renderLoader = () => <p>Loading</p>

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    backgroundColor: '#000000',
    height: '100vh',
    // marginTop: '4rem',
    overflow: 'scroll',
    padding: theme.spacing(3),
    width: '100vw',
  },
}))

const App = observer(
  (): JSX.Element => {
    const classes = useStyles()
    // const { t } = useTranslation()

    return (
      <>
        {/* <AmplifySignOut /> */}
        <div className={classes.root}>
          <CssBaseline />
          <main className={classes.content}>
            <Suspense fallback={renderLoader()}>
              <Switch>
                <Route path='/' exact component={HomePage} />
                <Route component={HomePage} />
              </Switch>
            </Suspense>
          </main>
        </div>
      </>
    )
  }
)

export default withAuthenticator(App)

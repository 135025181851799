const locales = {
  nl: {
    'Sign in to your account': 'Inloggen bij Manege Manager',
    'Sign In': 'INLOGGEN',
    Username: 'Email',
    Password: 'Wachtwoord',
    'Enter your username': 'Voer Emailadres in',
    'Enter your password': 'Voer wachtwoord in',
    'Forget your password? ': ' ',
    'Reset password': 'Wachtwoord vergeten',
    'common.People': 'personen',
  },
}

export default locales

import { ThemeProvider } from '@material-ui/styles'
import Amplify, { I18n } from 'aws-amplify'
import 'mobx-react-lite/batchingForReactDom'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import App from './App'
import AwsConfig from './aws-exports'
import history from './history'
import './i18n'
import './index.css'
import locales from './locales/aws'
import * as serviceWorker from './serviceWorker'
import theme from './theme/muiTheme'

Amplify.configure(AwsConfig)
I18n.setLanguage('nl')

I18n.putVocabularies(locales)

ReactDOM.render(
  <Router history={history}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

export const COLOR_DIRTY = '#000fff'
export const COLOR_DIRTY_IMPORTANT = '#000fff !important'
export const COLOR_ERROR = '#f44336'
export const COLOR_ERROR_IMPORTANT = '#f44336 !important'
export const COLOR_PRIMARY = '#009485'
export const COLOR_PRIMARY_IMPORTANT = '#009485 !important'
export const COLOR_PRIMARY_INV = '#FFFFFF'
export const COLOR_PRIMARY_INV_IMPORTANT = '#FFFFFF !important'
export const COLOR_PRIMARY_SHADE_1 = '#008577'
export const COLOR_PRIMARY_SHADE_2 = '#00766A'
export const COLOR_PRIMARY_SHADE_3 = '#00675D'
export const COLOR_PRIMARY_SHADE_4 = '#00584F'
export const COLOR_PRIMARY_SHADE_5 = '#004A42'
export const COLOR_PRIMARY_SHADE_6 = '#003B35'
export const COLOR_PRIMARY_SHADE_7 = '#002C27'
export const COLOR_PRIMARY_SHADE_8 = '#001D1A'
export const COLOR_PRIMARY_SHADE_9 = '#000E0D'
export const COLOR_PRIMARY_TINT_1 = '#199E91'
export const COLOR_PRIMARY_TINT_2 = '#33A99D'
export const COLOR_PRIMARY_TINT_3 = '#4CB4A9'
export const COLOR_PRIMARY_TINT_4 = '#66BEB5'
export const COLOR_PRIMARY_TINT_5 = '#7FC9C2'
export const COLOR_PRIMARY_TINT_6 = '#99D4CE'
export const COLOR_PRIMARY_TINT_7 = '#B2DEDA'
export const COLOR_PRIMARY_TINT_8 = '#CCE9E6'
export const COLOR_PRIMARY_TINT_9 = '#E5F4F2'

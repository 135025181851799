import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import commonNL from '../locales/nl/common.json'
import constantsNL from '../locales/nl/constants.json'

i18n.use(initReactI18next).init({
  lng: 'nl',
  ns: ['common', 'constants', 'menu', 'muiTable', 'people', 'settings', 'text'],
  defaultNS: 'common',
  resources: {
    nl: {
      common: commonNL,
      constants: constantsNL,
    },
  },
  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n
